// pages/wer-wir-sind.js

import React from 'react'
import { Link } from 'gatsby-plugin-modal-routing-3'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import styled from '@emotion/styled'
import Layout from '../components/layout_under'
import Image from '../components/image'


const BoxShadow = styled.div 
`box-shadow: rgba(240, 46, 170, 0.4) 0px 5px inset, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px inset, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;
&:after{ box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  flex:1;

}
`


const WerWirSind = () => (
  <Layout >
    <BoxShadow css={{
            width: "50%",
            height: "auto",
          }}><Image src="threepic1_schmal.jpg" /></BoxShadow>
  <ModalRoutingContext.Consumer >
    {({ modal, closeTo }) => (
  
      <div >
        <br></br>
          <header>
           <h1>
            Tofu für Freunde            </h1>
          </header>
          

          <p>Kochexperimente, kultursoziologische Arbeit, kulinarische Feldforschung
          Wir sind das limeriki Kollektiv aus Leipzig und produzieren in Handarbeit Tofu und Okarabällchen.</p>
           
          <p>Tofu für Freunde - was als Kochexperiment begonnen hat, wurde zu einer Geschäftsidee. Was in
          eigener Küche oder im Hinterhof geschah, verlagerte sich in ein professionelles Umfeld.
          Tofu ist für uns ein Handwerk.
          Unser Credo lautet – die ganze Bohne! Wir wollen die ganze Sojabohne verwenden. Aus
          Sojatrester, in Japan Okara genannt, stellen wir Okarabällchen her.
          Unsere Okarabällchen gibt‘s in verschiedenen Variationen und sie sind ein Superfood & Snack
          gleichzeitig!</p>

            <p>Unser Kollektiv besteht aus Künstlern, Köchen, Philosophen, Programmierern, Träumern und
            Unternehmern. Wir befinden uns gerade in einem Transformationsprozess, denn wir sind eine
            Genossenschaft in Gründung.</p>
        {modal ? (
            <Link to={closeTo}>
            Zurück

          </Link>
        ) : (
          <Link to="/"  > Hauptseite</Link>
        )}

       
      </div>
       
    )
    
    
    }
  </ModalRoutingContext.Consumer>
  </Layout>
)

export default WerWirSind