
/** @jsx jsx */

import React from "react"
import  { Link }  from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {jsx, css} from '@emotion/react'
import Header from "./header"
import "./layout.css"
import "./popup/modal.css"

const breakpoints =[480]


const mq = breakpoints.map(
  bp => `@media(min-width: ${bp}px)`
)


const Layout = ({ children }) => {
  

  return (
    <>
   
      
        <main css={{
             display:`flex`,
              maxWidth:`90%`,
              height:`90%`,
              border:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
              flexDirection: "column",
             
              alignItems: "center",
              justifyItems: "center",
              
              [mq[0]]:{
                margin: '1em',
              },
       }}>
      <Header siteTitle='Limeriki' />
      {children}</main>
      
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> from <a href="https://zeitfuctory.de">zeitf*ctory </a>by <a href="https://www.gwki.de">beny </a>
          picture by <a href="https://wimmelfoto.de">Christian Richter (wimmelfoto.de) </a>  
        <Link to="/impressum">Impressum</Link>
        </footer>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
